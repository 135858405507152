import Splide from '@splidejs/splide';

type Elements = {
  sliders: HTMLElement[];
};

type Hero = {
  elements: Elements;
  init: () => void;
  bindEvents: () => void;
  initializeSliders: () => void;
  setupCustomNavigation: (slider: HTMLElement) => void;
};

const hero: Hero = {
  elements: {
    sliders: [],
  },

  init() {
    this.elements.sliders = Array.from(document.querySelectorAll<HTMLElement>('.module__hero .splide'));
    this.bindEvents();
  },

  bindEvents() {
    if (this.elements.sliders.length > 0) {
      this.initializeSliders();
    }
  },

  initializeSliders() {
    this.elements.sliders.forEach(slider => {
      this.setupCustomNavigation(slider);

      const splide = new Splide(slider, {
        type: 'loop',
        perPage: 1,
        autoplay: true,
        interval: 7000,
        arrows: true,
        pagination: false,
      });

      splide.on('mounted', () => {
        if (splide.length === 1) {
          splide.options = { ...splide.options, drag: false };
        }
      });

      splide.mount();
    });
  },

  setupCustomNavigation(slider: HTMLElement) {
    const buttons: HTMLButtonElement[] = Array.from(slider.querySelectorAll('.module__slide--button button'));

    buttons.forEach(button => {
      button.addEventListener('click', () => {
        const isPrevious = button.dataset.action === 'previous';
        const selector = isPrevious ? '.splide__arrow--prev' : '.splide__arrow--next';
        const splideButton: HTMLButtonElement | null = slider.querySelector(selector);

        if (splideButton) {
          splideButton.click();
        }
      });
    });
  },
};

export default hero;
