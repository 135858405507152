/* Scripts: Parts */
import header from '@/scripts/parts/header';
import navigation from '@/scripts/parts/navigation';
import footer from '@/scripts/parts/footer';
import search from '@/scripts/parts/search';

/* Scripts: Modules */
import divider from '@/scripts/modules/divider';
import event from '@/scripts/modules/event';
import events from '@/scripts/modules/events';
import eventsList from '@/scripts/modules/events-list';
import gallerySlider from '@/scripts/modules/gallery-slider';
import hero from '@/scripts/modules/hero';
import news from '@/scripts/modules/news';
import newsList from '@/scripts/modules/news-list';
import peopleSearch from '@/scripts/modules/people-search';
import quotes from '@/scripts/modules/quotes';
import sectionSlider from '@/scripts/modules/section-slider';

/* Scripts: Elements */
import designs from '@/scripts/elements/designs';
import typography from '@/scripts/elements/typography';
import iframes from '@/scripts/elements/iframes';

/* Initialize: Parts */
header.init();
navigation.init();
footer.init();
search.init();

/* Initialize: Modules */
divider.init();
event.init();
events.init();
eventsList.init();
gallerySlider.init();
hero.init();
news.init();
newsList.init();
peopleSearch.init();
quotes.init();
sectionSlider.init();

/* Initialize: Elements */
designs.init();
typography.init();
iframes.init();

/* Styles */
import './styles/main.scss';
import '@splidejs/splide/dist/css/splide.min.css';
import 'slick-carousel/slick/slick.css';

/* Assets */
import './fonts/Curtis-Regular.otf';

/* Ready */
window.addEventListener('DOMContentLoaded', () => {
  console.log('Assets Loaded');
});
