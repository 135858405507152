type Elements = {
  buttons: HTMLAnchorElement[];
};

type News = {
  elements: Elements;
  init: () => void;
  bindEvents: () => void;
};

const news: News = {
  elements: {
    buttons: [],
  },

  init() {
    this.elements.buttons = Array.from(document.querySelectorAll('.module__slider--nav a'));
    this.bindEvents();
  },

  bindEvents() {
    if (this.elements.buttons.length) {
      this.elements.buttons.forEach((button) => {
        const label = button.querySelector('label');
        if (label) {
          label.addEventListener('click', (event) => {
            event.preventDefault(); // Prevent default label behavior
            button.click();
          });
        }
      });
    }
  },
};

export default news;
