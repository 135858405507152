type Elements = {
  eventsLoadMore: HTMLButtonElement | null;
  eventsResultsList: HTMLUListElement | null;
  postsLoadMore: HTMLButtonElement | null;
  postsResultsList: HTMLUListElement | null;
}

type Search = {
  postsPerPage: number;
  elements: Elements;
  init: () => void;
  bindEvents: () => void;
  getResults: (page: number, type: string) => void;
  handleLoadMoreClick: (type: 'events' | 'posts') => void;
};

const search: Search = {
  postsPerPage: 10,

  elements: {
    eventsLoadMore: document.querySelector('.site-search__events button[data-action="load-more"]'),
    eventsResultsList: document.querySelector('.site-search__events .site-search__results'),
    postsLoadMore: document.querySelector('.site-search__posts button[data-action="load-more"]'),
    postsResultsList: document.querySelector('.site-search__posts .site-search__results'),
  },

  init() {
    this.bindEvents();
  },

  bindEvents() {
    console.log('Search module initialized');

    this.elements.eventsLoadMore?.addEventListener('click', () => this.handleLoadMoreClick('events'));
    this.elements.postsLoadMore?.addEventListener('click', () => this.handleLoadMoreClick('posts'));
  },

  handleLoadMoreClick(type) {
    const button = type === 'events' ? this.elements.eventsLoadMore : this.elements.postsLoadMore;
    const newPage = parseInt(button?.dataset.page || '1') + 1;
    this.getResults(newPage, type);
  },

  getResults(page, type) {
    console.log('page', page);
    const button = type === 'events' ? this.elements.eventsLoadMore : this.elements.postsLoadMore;
    const resultsList = type === 'events' ? this.elements.eventsResultsList : this.elements.postsResultsList;
    const showingCount = page * this.postsPerPage;

    window.jQuery.ajax({
      method: 'POST',
      url: window.curtis.ajaxurl,
      data: {
        action: 'action_get_search',
        page,
        type,
        query: button?.dataset.query
      },
    })
    .success((success: string) => {
      const data = JSON.parse(success);

      if(resultsList && data.results && page > 1) {
        resultsList.innerHTML = `${resultsList.innerHTML}${data.results}`;
      } else if(resultsList && data.results && page === 1) {
        resultsList.innerHTML = data.results;
      } else if(resultsList && !data.results) {
        resultsList.innerHTML = '<li class="not-found">No results found</li>';
      }

      /* update the page number */
      button?.setAttribute('data-page', `${page}`);

      /* hide the load button if we have all results */
      if(page === -1 || showingCount >= data.total) {
        button?.classList.add('hidden');
      }
    })
    .fail((_jqXHR: JQuery.jqXHR, _textStatus: string, errorThrown: string) => console.log(errorThrown));
  }

};

export default search;
