import 'slick-carousel';

type GallerySlider = {
  elements: {
    sliders: HTMLElement[];
    captionArea: HTMLElement | null;
  };
  init: () => void;
  bindEvents: () => void;
  setCaption: (slide: HTMLElement | null) => void;
  createSlider: (slider: HTMLElement) => void;
};

const gallerySlider: GallerySlider = {
  elements: {
    sliders: Array.from(document.querySelectorAll<HTMLElement>('.module__gallery-slider .gallery-slider__slides')),
    captionArea: document.querySelector<HTMLElement>('.module__gallery-slider .gallery-slider__caption'),
  },

  init() {
    this.bindEvents();
  },

  bindEvents() {
    if(this.elements.sliders.length > 0) {
      this.elements.sliders.forEach(slider => {
        this.createSlider(slider);
      });
    }
  },

  setCaption(slide: HTMLElement | null) {
    if(!slide || !this.elements.captionArea) return;

    const caption = slide.dataset.caption;

    if(caption) {
      this.elements.captionArea.innerHTML = caption;
    } else {
      this.elements.captionArea.innerHTML = '&nbsp;';
    }
  },

  createSlider(slider: HTMLElement) {
    const $slick = window.jQuery(slider);

    $slick.on('afterChange', (_e: JQuery.Event, _slick: JQuerySlick, currentSlide: number) => {
      const slide = slider.querySelector<HTMLElement>('.gallery-slider__slide[data-slick-index="' + currentSlide + '"] .gallery-slider__slide--contents');
      this.setCaption(slide);
    });

    $slick.slick({
      centerMode: true,
      centerPadding: '150px',
      slidesToShow: 1,
      adaptiveHeight: false,
      slide: '.gallery-slider__slide',
      rows: 0,
      arrows: false,
      dots: true,
      autoplay: true,
      customPaging: function() {
        return '<button type="button"><svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9276 0.670298C6.97533 2.70607 0.5 9.55889 0.5 15.8268C0.5 22.0947 6.97533 25.4437 14.9276 23.4021C22.8741 21.2173 29.5 14.3645 29.5 8.1023C29.5 1.84015 22.8741 -1.52031 14.9276 0.670298Z" fill="black"/></svg></button>';
      },
      responsive: [
        {
          breakpoint: 999,
          settings: {
            centerPadding: '32px'
          }
        }
      ]
    });
  }
};

export default gallerySlider;
