type Elements = {
  module: HTMLElement | null;
  categories: HTMLUListElement | null;
  loadMore: HTMLButtonElement | null;
  resultsList: HTMLUListElement | null;
}

type Inputs = {
  category: HTMLInputElement | null;
}

type NewsList = {
  postsPerPage: number;
  elements: Elements;
  inputs: Inputs;
  init: () => void;
  setInputs: () => void;
  bindEvents: () => void;
  getValues: () => {
    page: string | null;
    category: string | null;
  };
  getResults: (page: number) => void;
  handleCategoryChange: (input: HTMLInputElement) => void;
  handleLoadMoreClick: () => void;
}

const newsList: NewsList = {
  postsPerPage: 6,

  elements: {
    module: document.querySelector('.module--news-list'),
    categories: document.querySelector('.module--news-list .module__filters'),
    loadMore: document.querySelector('.module--news-list button[data-action="load-more"]'),
    resultsList: document.querySelector('.module--news-list .module__content--list ul'),
  },

  inputs: {
    category: null
  },

  init() {
    this.setInputs();
    this.bindEvents();
  },

  setInputs() {
    if(!this.elements.categories) return;

    this.inputs.category = this.elements.categories.querySelector('input[type="radio"]:checked');
  },

  bindEvents() {
    const radios: HTMLInputElement[] = Array.from(this.elements.categories?.querySelectorAll('input[type="radio"]') || []);
    if(radios.length > 0) {
      radios.forEach((radio) => {
        radio.addEventListener('change', () => this.handleCategoryChange(radio));
      });
    }

    this.elements.loadMore?.addEventListener('click', () => this.handleLoadMoreClick());
  },

  getValues() {
    return {
      page: this.elements.module?.dataset.page || '1',
      category: this.inputs.category?.value || null
    };
  },

  getResults(page) {
    const values = this.getValues();
    const showingCount = page * this.postsPerPage;

    this.elements.module?.classList.add('loading');

    window.jQuery.ajax({
      method: 'POST',
      url: window.curtis.ajaxurl,
      data: {
        action: 'action_get_articles',
        selected: { ...values, page }
      },
    })
    .success((success: string) => {
      const data = JSON.parse(success);

      /* update the results html */
      if(this.elements.resultsList && data.results && page > 1) {
        this.elements.resultsList.innerHTML = `${this.elements.resultsList.innerHTML}${data.results}`;
      } else if(this.elements.resultsList && data.results && page === 1) {
        this.elements.resultsList.innerHTML = data.results;
      } else if(this.elements.resultsList && !data.results) {
        this.elements.resultsList.innerHTML = '<li class="not-found">No results found</li>';
      }

      /* update the page number */
      this.elements.module?.setAttribute('data-page', `${page}`);

      /* hide the load button if we have all results */
      if(page === -1 || showingCount >= data.total) {
        this.elements.loadMore?.classList.add('hidden');
      }
    })
    .fail((_jqXHR: JQuery.jqXHR, _textStatus: string, errorThrown: string) => console.log(errorThrown))
    .always(() => this.elements.module?.classList.remove('loading'));
  },

  handleCategoryChange() {
    this.inputs.category = this.elements.categories?.querySelector('input[type="radio"]:checked') || null;
    this.elements.module?.setAttribute('data-page', '1');
    this.getResults(1);
  },

  handleLoadMoreClick() {
    const newPage = parseInt(this.elements.module?.dataset.page || '1') + 1;
    this.getResults(newPage);
  },
};

export default newsList;
